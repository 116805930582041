import useIsMobile from "../../functions/mobileCheck";
import { Navigate } from "react-router-dom";
import HeaderWMobile from "../../components/HeaderWMobile";
import Carousel from "react-bootstrap/Carousel";
import { useEffect } from "react";

import img_001_1 from "../../img/work/w001/001_1.jpg";
import img_001_2 from "../../img/work/w001/001_2.jpg";
import img_001_3 from "../../img/work/w001/001_3.jpg";
import img_001_4 from "../../img/work/w001/001_4.jpg";
import img_001_5 from "../../img/work/w001/001_5.jpg";
import img_001_6 from "../../img/work/w001/001_6.jpg";
import img_001_7 from "../../img/work/w001/001_7.jpg";
import img_001_8 from "../../img/work/w001/001_8.jpg";
import img_001_9 from "../../img/work/w001/001_9.jpg";
import img_001_10 from "../../img/work/w001/001_10.jpg";
import img_001_11 from "../../img/work/w001/001_11.jpg";
import img_001_12 from "../../img/work/w001/001_12.jpg";
import img_001_13 from "../../img/work/w001/001_13.jpg";
import img_001_14 from "../../img/work/w001/001_14.jpg";
import img_001_15 from "../../img/work/w001/001_15.jpg";
import img_001_16 from "../../img/work/w001/001_16.jpg";
import img_001_17 from "../../img/work/w001/001_17.jpg";
import img_001_18 from "../../img/work/w001/001_18.jpg";
import img_001_19 from "../../img/work/w001/001_19.jpg";
import img_001_20 from "../../img/work/w001/001_20.jpg";
import mov_001 from "../../img/work/w001/001_21.mp4";

import img_002_1 from "../../img/work/w002/002_1.jpg";
import img_002_2 from "../../img/work/w002/002_2.jpg";
import img_002_3 from "../../img/work/w002/002_3.jpg";
import img_002_4 from "../../img/work/w002/002_4.jpg";
import img_002_5 from "../../img/work/w002/002_5.jpg";
import img_002_6 from "../../img/work/w002/002_6.jpg";
import img_002_7 from "../../img/work/w002/002_7.jpg";
import img_002_8 from "../../img/work/w002/002_8.jpg";
import img_002_9 from "../../img/work/w002/002_9.jpg";
import img_002_10 from "../../img/work/w002/002_10.jpg";
import img_002_11 from "../../img/work/w002/002_11.jpg";
import img_002_12 from "../../img/work/w002/002_12.jpg";
import img_002_13 from "../../img/work/w002/002_13.jpg";
import mov_002 from "../../img/work/w002/002_14.mp4";

import img_003_1 from "../../img/work/w003/003_1.jpg";
import img_003_2 from "../../img/work/w003/003_2.jpg";
import img_003_3 from "../../img/work/w003/003_3.jpg";
import img_003_4 from "../../img/work/w003/003_4.jpg";
import img_003_5 from "../../img/work/w003/003_5.jpg";
import img_003_6 from "../../img/work/w003/003_6.jpg";
import img_003_7 from "../../img/work/w003/003_7.jpg";
import img_003_8 from "../../img/work/w003/003_8.jpg";
import img_003_9 from "../../img/work/w003/003_9.jpg";
import img_003_10 from "../../img/work/w003/003_10.jpg";

import img_004_1 from "../../img/work/w004/004_1.jpg";
import img_004_2 from "../../img/work/w004/004_2.jpg";
import img_004_3 from "../../img/work/w004/004_3.jpg";
import img_004_4 from "../../img/work/w004/004_4.jpg";
import img_004_5 from "../../img/work/w004/004_5.jpg";
import img_004_6 from "../../img/work/w004/004_6.jpg";
import img_004_7 from "../../img/work/w004/004_7.jpg";
import img_004_8 from "../../img/work/w004/004_8.jpg";
import img_004_9 from "../../img/work/w004/004_9.jpg";
import img_004_10 from "../../img/work/w004/004_10.jpg";
import img_004_11 from "../../img/work/w004/004_11.jpg";
import mov_004 from "../../img/work/w004/004_12.mp4";

import img_005_1 from "../../img/work/w005/005_1.jpg";
import img_005_2 from "../../img/work/w005/005_2.jpg";
import img_005_3 from "../../img/work/w005/005_3.jpg";
import img_005_4 from "../../img/work/w005/005_4.jpg";
import img_005_5 from "../../img/work/w005/005_5.jpg";
import img_005_6 from "../../img/work/w005/005_6.jpg";
import img_005_7 from "../../img/work/w005/005_7.jpg";

import img_006_1 from "../../img/work/w006/006_1.jpg";
import img_006_2 from "../../img/work/w006/006_2.jpg";
import img_006_3 from "../../img/work/w006/006_3.jpg";
import img_006_4 from "../../img/work/w006/006_4.jpg";
import img_006_5 from "../../img/work/w006/006_5.jpg";
import img_006_6 from "../../img/work/w006/006_6.jpg";
import img_006_7 from "../../img/work/w006/006_7.jpg";
import img_006_8 from "../../img/work/w006/006_8.jpg";
import img_006_9 from "../../img/work/w006/006_9.jpg";

import img_007_1 from "../../img/work/w007/007_1.jpg";
import img_007_2 from "../../img/work/w007/007_2.jpg";
import img_007_3 from "../../img/work/w007/007_3.jpg";
import img_007_4 from "../../img/work/w007/007_4.jpg";
import img_007_5 from "../../img/work/w007/007_5.jpg";

import img_008_1 from "../../img/work/w008/008_1.jpg";
import mov_008 from "../../img/work/w008/008_2.mp4";
import img_008_3 from "../../img/work/w008/008_3.jpg";
import img_008_4 from "../../img/work/w008/008_4.jpg";
import img_008_5 from "../../img/work/w008/008_5.jpg";
import img_008_6 from "../../img/work/w008/008_6.jpg";

import img_009_1 from "../../img/work/w009/009_1.jpg";
import img_009_2 from "../../img/work/w009/009_2.jpg";
import img_009_3 from "../../img/work/w009/009_3.jpg";

const WMobile = () => {
    const isMobile = useIsMobile();

    useEffect(() => {
        localStorage.setItem("xplorable2", true);
        localStorage.setItem("xplorable3", true);
        localStorage.setItem("xplorable4", true);
        localStorage.setItem("xplorable5", true);
        localStorage.setItem("xplorable6", true);
        localStorage.setItem("xplorable7", true);
        localStorage.setItem("xplorable8", true);
        localStorage.setItem("xplorable9", true);
        localStorage.setItem("xplorable10", true);
        localStorage.setItem("xplorable11", true);
        localStorage.setItem("xplorable12", true);
        localStorage.setItem("xplorable13", true);
        localStorage.setItem("xplorable16", true);
        localStorage.setItem("xplorable17", true);

        document.getElementById("mov_001").play();
        document.getElementById("mov_002").play();
        document.getElementById("mov_004").play();
        document.getElementById("mov_008").play();
    }, []);

    return (
        <div className="WMobile">
            {!isMobile && (<Navigate to="/work/001"/>)}
            <HeaderWMobile/>
            <h1 className="mt-5 ms-2 display-1">001 kunststellwerk</h1>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img src={img_001_1} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_2} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_3} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_4} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_5} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_6} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_7} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_8} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_9} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_10} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_11} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_12} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_13} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_14} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_15} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_16} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_17} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_18} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_19} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_20} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>                  
                    <video id="mov_001" width="100%" autoPlay loop muted src={mov_001}/>
                </Carousel.Item>
            </Carousel>
            <h1 className="ms-2 display-1">002 lernen im turm</h1>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img src={img_002_1} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_2} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_3} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_4} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_5} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_6} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_7} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_8} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_9} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_10} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_11} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_12} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_13} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>                  
                    <video id="mov_002" width="100%" autoPlay loop muted src={mov_002}/>
                </Carousel.Item>
            </Carousel>
            <h1 className="ms-2 display-1">003 sportbrücke</h1>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img src={img_003_1} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_003_2} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_003_3} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_003_4} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_003_5} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_003_6} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_003_7} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_003_8} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_003_9} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_003_10} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
            </Carousel>
            <h1 className="ms-2 display-1">004 wandelbar</h1>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img src={img_004_1} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_004_2} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_004_3} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_004_4} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_004_5} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_004_6} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_004_7} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_004_8} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_004_9} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_004_10} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_004_11} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <video id="mov_004" width="100%" autoPlay loop muted src={mov_004}/>
                </Carousel.Item>
            </Carousel>
            <h1 className="ms-2 display-1">005 reservoir 27</h1>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img src={img_005_1} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_005_2} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_005_3} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_005_4} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_005_5} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_005_6} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_005_7} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
            </Carousel>
            <h1 className="ms-2 display-1">006 werklauf</h1>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img src={img_006_1} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_006_2} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_006_3} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_006_4} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_006_5} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_006_6} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_006_7} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_006_8} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_006_9} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
            </Carousel>
            <h1 className="ms-2 display-1">007 drahtseilakt</h1>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img src={img_007_1} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_007_2} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_007_3} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_007_4} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_007_5} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
            </Carousel>
            <h1 className="ms-2 display-1">008 scrambled egg</h1>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img src={img_008_1} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>                  
                    <video id="mov_008" width="100%" autoPlay loop muted src={mov_008}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_008_3} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_008_4} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_008_5} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_008_6} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
            </Carousel>
            <h1 className="ms-2 display-1">009 utopia</h1>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img src={img_009_1} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_009_2} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_009_3} className="d-block w-100" alt="" title=""/>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default WMobile;