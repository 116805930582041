import Typewriter from "../../components/Typewriter.js";
import HeaderWork from "../../components/HeaderWork.js";
import NavBarWork from "../../components/NavBarWork.js";
import Carousel from "react-bootstrap/Carousel";
import img_1 from "../../img/work/w001/001_1.jpg";
import img_2 from "../../img/work/w001/001_2.jpg";
import img_3 from "../../img/work/w001/001_3.jpg";
import img_4 from "../../img/work/w001/001_4.jpg";
import img_5 from "../../img/work/w001/001_5.jpg";
import img_6 from "../../img/work/w001/001_6.jpg";
import img_7 from "../../img/work/w001/001_7.jpg";
import img_8 from "../../img/work/w001/001_8.jpg";
import img_9 from "../../img/work/w001/001_9.jpg";
import img_10 from "../../img/work/w001/001_10.jpg";
import img_11 from "../../img/work/w001/001_11.jpg";
import img_12 from "../../img/work/w001/001_12.jpg";
import img_13 from "../../img/work/w001/001_13.jpg";
import img_14 from "../../img/work/w001/001_14.jpg";
import img_15 from "../../img/work/w001/001_15.jpg";
import img_16 from "../../img/work/w001/001_16.jpg";
import img_17 from "../../img/work/w001/001_17.jpg";
import img_18 from "../../img/work/w001/001_18.jpg";
import img_19 from "../../img/work/w001/001_19.jpg";
import img_20 from "../../img/work/w001/001_20.jpg";
import mov from "../../img/work/w001/001_21.mp4";
import { Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useIsMobile from "../../functions/mobileCheck";

const W001 = () => {
  const isMobile = useIsMobile();

  const [navigateHome, setNavigateHome] = useState(false);
  const [showTypewriter, setShowTypewriter] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("isComingFromHome") === null) {
      setNavigateHome(true);
    }

    if (localStorage.getItem("001counter") === null) {
      localStorage.setItem("001counter", 1);
    } else if (localStorage.getItem("001counter") == 1) {
      setShowTypewriter(true);
      localStorage.setItem("001counter", 2);
    }

    if (localStorage.getItem("xplorable2")) {
      document.getElementById("xplorable2").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable3")) {
      document.getElementById("xplorable3").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable4")) {
      document.getElementById("xplorable4").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable5")) {
      document.getElementById("xplorable5").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable6")) {
      document.getElementById("xplorable6").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable7")) {
      document.getElementById("xplorable7").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable8")) {
      document.getElementById("xplorable8").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable9")) {
      document.getElementById("xplorable9").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable10")) {
      document.getElementById("xplorable10").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable11")) {
      document.getElementById("xplorable11").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable12")) {
      document.getElementById("xplorable12").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable16")) {
      document.getElementById("xplorable16").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable17")) {
      document.getElementById("xplorable17").style.opacity = "unset";
    }

    document.getElementById("mov").play();
  }, []);

  const xplored = (xplorable) => {
    document.getElementById(xplorable).style.opacity = "unset";
    localStorage.setItem(xplorable, true);
  }

  return (
    <div className="W001">
      {isMobile && (<Navigate to="/work"/>)}
      {navigateHome && (<Navigate to="/"/>)}
      {showTypewriter && (<Typewriter text="kunstellwerk. " delay={200}/>)}
      <HeaderWork/>
      <div className="row main">
        <div className="col-7 mt-custom">
          <Carousel id="xplorable3" onMouseOver={() => xplored("xplorable3")}>
            <Carousel.Item interval={2000}>
              <img src={img_1} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_2} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_3} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_4} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_5} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_6} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_7} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_8} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_9} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_10} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_11} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_12} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_13} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_14} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_15} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_16} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_17} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_18} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_19} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_20} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>                  
              <video id="mov" width="100%" autoPlay loop muted src={mov}/>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="col-4 mt-custom"></div> 
        <div className="col-1 mt-custom nav-col">
          <NavBarWork/>
        </div>  
      </div>
    </div>
  );
};

export default W001;
