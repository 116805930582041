import "../styles/common/NavBar.css";
import { NavLink } from "react-router-dom";
import { Component } from "react";

class NavBarShop extends Component {
    xplored = (xplorable) => {
        document.getElementById(xplorable).style.opacity = "unset";
        localStorage.setItem(xplorable, true);
    }

    render() {
        return (
            <ul className="NavBar nav flex-column">
                <li className="nav-item hidden" id="xplorable13" onMouseOver={() => this.xplored("xplorable13")}>
                    <NavLink exact to={"/shop/001"} className="nav-link text nav-text nav-first">001</NavLink>
                </li>
            </ul>
        );
    }
}

export default NavBarShop;
