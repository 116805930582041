import useIsMobile from "../../functions/mobileCheck";
import { Navigate } from "react-router-dom";
import HeaderSMobile from "../../components/HeaderSMobile";
import Carousel from "react-bootstrap/Carousel";
import { useEffect } from "react";
import Typewriter from "../../components/Typewriter.js";

import img_001_1 from "../../img/work/w001/001_1.jpg";
import img_001_2 from "../../img/work/w001/001_2.jpg";

import img_002_1 from "../../img/work/w002/002_1.jpg";
import img_002_2 from "../../img/work/w002/002_2.jpg";

const SMobile = () => {
    const isMobile = useIsMobile();

    useEffect(() => {
        localStorage.setItem("xplorable2", true);
        localStorage.setItem("xplorable3", true);
        localStorage.setItem("xplorable4", true);
        localStorage.setItem("xplorable5", true);
        localStorage.setItem("xplorable6", true);
        localStorage.setItem("xplorable7", true);
        localStorage.setItem("xplorable8", true);
        localStorage.setItem("xplorable9", true);
        localStorage.setItem("xplorable10", true);
        localStorage.setItem("xplorable11", true);
        localStorage.setItem("xplorable12", true);
        localStorage.setItem("xplorable13", true);
        localStorage.setItem("xplorable16", true);
        localStorage.setItem("xplorable17", true);
    }, []);

    return (
        <div className="SMobile">
            {!isMobile && (<Navigate to="/shop/001"/>)}
            <HeaderSMobile/>
            <Typewriter text="coming soon." delay={200} stay/>
            {/*<Carousel interval={null}>
                <Carousel.Item>
                    <img src={img_001_1} className="d-block w-100" alt="" title=""/>
                    <form action="https://www.instagram.com/janschreiber_" target="_blank">
                        <button className="button h2 mt-3 ms-3" type="button">order via dm "001"</button>
                    </form>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_001_2} className="d-block w-100" alt="" title=""/>
                    <form action="https://www.instagram.com/janschreiber_" target="_blank">
                        <button className="button h2 mt-3 ms-3" type="button">order via dm "001"</button>
                    </form>
                </Carousel.Item>
            </Carousel>
            <Carousel interval={null}>
                <Carousel.Item>
                    <img src={img_002_1} className="d-block w-100" alt="" title=""/>
                    <form action="https://www.instagram.com/janschreiber_" target="_blank">
                        <button className="button h2 mt-3 ms-3" type="button">order via dm "002"</button>
                    </form>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img_002_2} className="d-block w-100" alt="" title=""/>
                    <form action="https://www.instagram.com/janschreiber_" target="_blank">
                        <button className="button h2 mt-3 ms-3" type="button">order via dm "002"</button>
                    </form>
                </Carousel.Item>
            </Carousel>*/}
        </div>
    );
};

export default SMobile;