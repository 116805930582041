import {useEffect, useState} from "react";

const useIsMobile = () => {
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        
        return () => {window.removeEventListener("resize", handleWindowSizeChange);}
    }, []);

    return (width <= 980);
}

export default useIsMobile;