import React, { useState, useEffect } from "react";

const Typewriter = ({ text, delay, infinite, stay }) => {
    const [currentText, setCurrentText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        let timeout;

        if (currentIndex <= text.length) {
            timeout = setTimeout(() => {
                setCurrentText(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            }, delay);
        } else {
            if (stay) {
                setCurrentText(text);
                setCurrentIndex(text.length + 1);
            } else {
                setCurrentText("");
            }
        }

        return () => clearTimeout(timeout);
    }, [currentIndex, delay, infinite, text, stay]);

    return <h1 className="typewriter">{currentText}</h1>;
};

export default Typewriter;