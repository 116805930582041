import "../styles/common/Header.css";
import { Component } from "react";
import { NavLink } from "react-router-dom";

class HeaderWMobile extends Component {
    render() {
        return (
            <div className="Header">
                <div className="row">
                    <div className="col-6">
                        <a className="display-1" href="https://www.instagram.com/janschreiber_" target="_blank" rel="noreferrer">janschreiber_</a>
                    </div>
                    <div className="col-6 text-end">
                        <NavLink to={"/shop"} className="display-1" class="nav-link">shop_</NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderWMobile;