import Typewriter from "../../components/Typewriter.js";
import HeaderWork from "../../components/HeaderWork.js";
import NavBarWork from "../../components/NavBarWork.js";
import Carousel from "react-bootstrap/Carousel";
import img_1 from "../../img/work/w004/004_1.jpg";
import img_2 from "../../img/work/w004/004_2.jpg";
import img_3 from "../../img/work/w004/004_3.jpg";
import img_4 from "../../img/work/w004/004_4.jpg";
import img_5 from "../../img/work/w004/004_5.jpg";
import img_6 from "../../img/work/w004/004_6.jpg";
import img_7 from "../../img/work/w004/004_7.jpg";
import img_8 from "../../img/work/w004/004_8.jpg";
import img_9 from "../../img/work/w004/004_9.jpg";
import img_10 from "../../img/work/w004/004_10.jpg";
import img_11 from "../../img/work/w004/004_11.jpg";
import mov from "../../img/work/w004/004_12.mp4";
import { Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useIsMobile from "../../functions/mobileCheck";

const W004 = () => {
  const isMobile = useIsMobile();

  const [isComingFromHome, setIsComingFromHome] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("isComingFromHome") === null) {
      setIsComingFromHome(true);
    }

    if (localStorage.getItem("has004BeenVisited") === null) {
      localStorage.setItem("has004BeenVisited", true);
      setIsFirstVisit(true);
    }

    if (localStorage.getItem("xplorable2")) {
      document.getElementById("xplorable2").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable3")) {
      document.getElementById("xplorable3").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable4")) {
      document.getElementById("xplorable4").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable5")) {
      document.getElementById("xplorable5").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable6")) {
      document.getElementById("xplorable6").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable7")) {
      document.getElementById("xplorable7").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable8")) {
      document.getElementById("xplorable8").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable9")) {
      document.getElementById("xplorable9").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable10")) {
      document.getElementById("xplorable10").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable11")) {
      document.getElementById("xplorable11").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable12")) {
      document.getElementById("xplorable12").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable16")) {
      document.getElementById("xplorable16").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable17")) {
      document.getElementById("xplorable17").style.opacity = "unset";
    }

    document.getElementById("mov").play();
  }, []);

  const xplored = (xplorable) => {
    document.getElementById(xplorable).style.opacity = "unset";
    localStorage.setItem(xplorable, true);
  }

  return (
    <div className="W004">
      {isMobile && (<Navigate to="/work"/>)}
      {isComingFromHome && (<Navigate to="/"/>)}
      {isFirstVisit && (<Typewriter text="wandelbar. " delay={200}/>)}
      <HeaderWork/>
      <div className="row main">
        <div className="col-7 mt-custom">
          <Carousel id="xplorable3" onMouseOver={() => xplored("xplorable3")}>
            <Carousel.Item interval={2000}>
              <img src={img_1} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_2} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_3} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_4} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_5} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_6} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_7} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_8} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_9} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_10} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img src={img_11} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <video id="mov" width="100%" autoPlay loop muted src={mov}/>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="col-4 mt-custom"></div> 
        <div className="col-1 mt-custom nav-col">
          <NavBarWork/>
        </div>
      </div>
    </div>
  );
};

export default W004;
