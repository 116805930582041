import "../styles/common/Home.css";
import x from "../img/x.png";
import { Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useIsMobile from "../functions/mobileCheck";

const Home = () => {
    const isMobile = useIsMobile();

    const [isXplore, setXplore] = useState(false);

    useEffect(() => {
        localStorage.clear();
    }, []);

    const xplore = () => {
        localStorage.setItem("isComingFromHome", true);
        setXplore(true);
    }

    return (
        <div className="Home">
            {isMobile && (<Navigate to="/work"/>)}
            {isXplore && (<Navigate to="/work/001"/>)}
            <img src={x} className="d-block" onMouseOver={xplore}/>
        </div>
    ); 
}

export default Home;