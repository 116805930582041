import "../styles/common/Header.css";
import { NavLink } from "react-router-dom";

const HeaderWork = () => {
    const xplored = (xplorable) => {
        document.getElementById(xplorable).style.opacity = "unset";
        localStorage.setItem(xplorable, true);
    }

    return (
        <div className="Header">
            <div className="row">
                <div className="col-4 hidden" id="xplorable2" onMouseOver={() => xplored("xplorable2")}>
                    <a className="text" href="https://www.instagram.com/janschreiber_" target="_blank">janschreiber_</a>
                </div>
                <div className="col-3 text-end hidden" id="xplorable5" onMouseOver={() => xplored("xplorable5")}>
                    <NavLink to={"/shop/001"} className="text nav-link">shop_</NavLink>
                </div>
                <div className="col-5 text-end hidden" id="xplorable4" onMouseOver={() => xplored("xplorable4")}>
                    <a className="text">48°n_9°e</a>
                </div>
            </div>
        </div>
    );
}

export default HeaderWork;