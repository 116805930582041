import HeaderShop from "../../components/HeaderShop.js";
import NavBarShop from "../../components/NavBarShop.js";
import Carousel from "react-bootstrap/Carousel";
import img_1 from "../../img/work/w001/001_1.jpg";
import img_2 from "../../img/work/w001/001_2.jpg";
import { Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useIsMobile from "../../functions/mobileCheck";
import Typewriter from "../../components/Typewriter.js";

const S001 = () => {
  const isMobile = useIsMobile();

  const [isComingFromHome, setIsComingFromHome] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("isComingFromHome") === null) {
      setIsComingFromHome(true);
    }

    if (localStorage.getItem("xplorable2")) {
      document.getElementById("xplorable2").style.opacity = "unset";
    }

    {/*if (localStorage.getItem("xplorable3")) {
      document.getElementById("xplorable3").style.opacity = "unset";
    }*/}

    if (localStorage.getItem("xplorable4")) {
      document.getElementById("xplorable4").style.opacity = "unset";
    }

    if (localStorage.getItem("xplorable5")) {
      document.getElementById("xplorable5").style.opacity = "unset";
    }

    {/*if (localStorage.getItem("xplorable13")) {
      document.getElementById("xplorable13").style.opacity = "unset";
    }*/}
  }, []);

  const xplored = (xplorable) => {
    document.getElementById(xplorable).style.opacity = "unset";
    localStorage.setItem(xplorable, true);
  }

  return (
    <div className="S001">
      {isMobile && (<Navigate to="/shop"/>)}
      {isComingFromHome && (<Navigate to="/"/>)}
      <HeaderShop/>
      <Typewriter text="coming soon." delay={200} stay/>
      {/*<div className="row main">
        <div className="col-7 mt-custom">
          <Carousel id="xplorable3" onMouseOver={() => xplored("xplorable3")}>
            <Carousel.Item interval={2000} className="text-center">
              <img src={img_1} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
            <Carousel.Item interval={2000} className="text-center">
              <img src={img_2} className="d-block w-100" alt="" title=""/>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="col-4 mt-custom"></div> 
        <div className="col-1 mt-custom nav-col">
          <NavBarShop/>
        </div>
      </div>*/}
    </div>
  );
};

export default S001;
