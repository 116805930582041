import "../styles/common/NavBar.css";
import { NavLink } from "react-router-dom";
import { Component } from "react";

class NavBarWork extends Component {
    xplored = (xplorable) => {
        document.getElementById(xplorable).style.opacity = "unset";
        localStorage.setItem(xplorable, true);
    }

    render() {
        return (
            <ul className="NavBar nav flex-column">
                <li className="nav-item hidden" id="xplorable6" onMouseOver={() => this.xplored("xplorable6")}>
                    <NavLink exact to={"/work/001"} className="nav-link text nav-text nav-first">001</NavLink>
                </li>
                <li className="nav-item hidden" id="xplorable7" onMouseOver={() => this.xplored("xplorable7")}>
                    <NavLink to={"/work/002"} className="nav-link text nav-text">002</NavLink>
                </li>
                <li className="nav-item hidden" id="xplorable8" onMouseOver={() => this.xplored("xplorable8")}>
                    <NavLink to={"/work/003"} className="nav-link text nav-text">003</NavLink>
                </li>
                <li className="nav-item hidden" id="xplorable9" onMouseOver={() => this.xplored("xplorable9")}>
                    <NavLink to={"/work/004"} className="nav-link text nav-text">004</NavLink>
                </li>
                <li className="nav-item hidden" id="xplorable10" onMouseOver={() => this.xplored("xplorable10")}>
                    <NavLink to={"/work/005"} className="nav-link text nav-text">005</NavLink>
                </li>
                <li className="nav-item hidden" id="xplorable11" onMouseOver={() => this.xplored("xplorable11")}>
                    <NavLink to={"/work/006"} className="nav-link text nav-text">006</NavLink>
                </li>
                <li className="nav-item hidden" id="xplorable12" onMouseOver={() => this.xplored("xplorable12")}>
                    <NavLink to={"/work/007"} className="nav-link text nav-text">007</NavLink>
                </li>
                <li className="nav-item hidden" id="xplorable16" onMouseOver={() => this.xplored("xplorable16")}>
                    <NavLink to={"/work/008"} className="nav-link text nav-text">008</NavLink>
                </li>
                <li className="nav-item hidden" id="xplorable17" onMouseOver={() => this.xplored("xplorable17")}>
                    <NavLink to={"/work/009"} className="nav-link text nav-text">009</NavLink>
                </li>
            </ul>
        );
    }
}

export default NavBarWork;
