import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";

import Home from "./pages/Home";
import WMobile from "./pages/work/WMobile";
import W001 from "./pages/work/W001";
import W002 from "./pages/work/W002";
import W003 from "./pages/work/W003";
import W004 from "./pages/work/W004";
import W005 from "./pages/work/W005";
import W006 from "./pages/work/W006";
import W007 from "./pages/work/W007";
import W008 from "./pages/work/W008";
import W009 from "./pages/work/W009";
import SMobile from "./pages/shop/SMobile";
import S001 from "./pages/shop/S001";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" exact element={<Home/>}/>
      <Route path="/work" element={<WMobile/>}/>
      <Route path="/work/001" element={<W001/>}/>
      <Route path="/work/002" element={<W002/>}/>
      <Route path="/work/003" element={<W003/>}/>
      <Route path="/work/004" element={<W004/>}/>
      <Route path="/work/005" element={<W005/>}/>
      <Route path="/work/006" element={<W006/>}/>
      <Route path="/work/007" element={<W007/>}/>
      <Route path="/work/008" element={<W008/>}/>
      <Route path="/work/009" element={<W009/>}/>
      <Route path="/shop" element={<SMobile/>}/>
      <Route path="/shop/001" element={<S001/>}/>
      <Route path="*" element={<Home/>}/>
    </Routes>
  </BrowserRouter>
);
